import React from "react";
import bannerImage from "../assets/images/home/banner.png";
import partnershipImage from "../assets/images/qui-sommes-nous/helloquence-5fNmWej4tAA-unsplash.jpg";
import nousContacterImage from "../assets/images/home/pexels-pixabay-236713.jpg";

const Home = () => {
  return (
    <>
      <section
        className="hero"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="hero-body" style={{ minHeight: "10rem" }}></div>
      </section>
      <section id="QuiSommesNous" className="section qui-sommes-nous">
        <div className="container columns">
          <div className="column is-three-fifths is-offset-one-fifth">
            <h1 className="title is-uppercase has-text-primary is-size-2 has-text-centered">
              Qui sommes nous
            </h1>
            <div className="image partnership-image">
              <img
                src={partnershipImage}
                alt="By Helloquence on Unsplash"
                title="Deux personnes faisant un brainstorming"
              />
            </div>
          </div>
        </div>
        <div className="container columns">
          <div className="column is-one-half is-offset-one-quarter">
            <div className="text">
              <h2 className="subtitle is-uppercase has-text-primary">
                Notre ambition
              </h2>
              <p className="is-size-5">
                IT Matters est une entreprise de services numériques qui aide
                les éditeurs de logiciel à réaliser leur transformation
                digitale.
              </p>
              <p className="is-size-5">
                Notre ambition est de répandre et populariser les bonnes
                pratiques liées au développement logiciel.
              </p>
              <p className="is-size-5">
                Notre accompagnement peut se réaliser sur l'ensemble d'un
                projet: depuis la définition du besoin, en passant par
                l'accompagnement pour la mise en place des méthodologies Agiles,
                la mise en place de processus d'intégration continue, de
                missions d'architectures, de mentoring ou encore de lead
                technique.
              </p>
            </div>
          </div>
        </div>
        <div className="container columns">
          <div className="column is-one-half is-offset-one-quarter">
            <h2 className="subtitle is-uppercase has-text-primary">
              Nous contacter
            </h2>
            <div className="image nous-contacter-image">
              <img
                src={nousContacterImage}
                alt="Photo by erica steeves on Unsplash"
                title="Main donnant une enveloppe"
              />
            </div>
          </div>
        </div>
        <div className="container columns">
          <div className="column is-one-half is-offset-one-quarter">
            <p className="is-size-5">
              Une question ? Une remarque ? Nous serons heureux de répondre à
              vos questions.
            </p>
            <div className="contact-button-container has-text-centered">
              <a
                className="button is-primary is-large"
                href="mailto:contact@it-matters.fr?subject=Demande de renseignements"
              >
                Nous contacter
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
