import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { RouteNames } from "./RouteNames";

import Home from "./pages/Home";
import MentionsLegales from "./pages/MentionsLegales";
import NosServices from "./pages/NosServices";
const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path={RouteNames.MentionsLegales} component={MentionsLegales} />
        <Route path={RouteNames.NosServices} component={NosServices} />
        <Route path="/" component={Home} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
