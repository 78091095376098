import React from "react";
import brainstormingImage from "../assets/images/nos-services/jo-szczepanska-bjemWZcNF34-unsplash.jpg";
import pairProgrammingImage from "../assets/images/nos-services/neonbrand-y_6rqStQBYQ-unsplash.jpg";

const NosServices = () => {
  return (
    <section id="NosServices" className="section">
      <div className="container columns">
        <div className="column is-three-fifths is-offset-one-fifth">
          <h1 className="title is-uppercase has-text-primary is-size-2 has-text-centered">
            Nos services
          </h1>
          <div className="image brainstorming-image">
            <img src={brainstormingImage} alt="By Jo Szczepanska on Unsplash" />
          </div>
        </div>
      </div>
      <div className="container columns">
        <div className="column is-half is-offset-one-quarter">
          <h2 className="subtitle is-uppercase has-text-primary">Méthode</h2>
          <p className="is-size-5">
            L'agilité dans les projets est une manière de fournir la visibilité
            nécessaire aux parties prenantes afin qu'elles puissent prendre les
            décisions stratégiques en fonction de données réelles.
          </p>
          <p className="is-size-5">
            L'adoption de la méthodologie agile est cependant un cap à passer
            qui peut être éprouvant: de nouveaux rôles apparaissent, d'autres se
            transforment et le périmètre de responsabilité évolue. Il peut être
            délicat par exemple pour un chef de projet de perdre contact avec
            son équipe même si c'est pour donner plus d'autonomie à celle-ci
            pour lui permettre de se concentrer sur des sujets plus orientés
            métier ou relation client.
          </p>
          <p className="is-size-5">
            Nous sommes en mesure de vous aider à la mise en place de ce cadre
            de travail et assurer l'accompagnement des différents acteurs
            jusqu'à ce qu'ils soient en pleine possession de leurs nouvelles
            responsabilités.
          </p>
        </div>
      </div>
      <div className="container columns">
        <div className="column is-half is-offset-one-quarter">
          <h2 className="subtitle is-uppercase has-text-primary">Technique</h2>
        </div>
      </div>
      <div className="container columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="image">
            <img src={pairProgrammingImage} alt="By NeONBRAND on Unsplash" />
          </div>
        </div>
      </div>
      <div className="container columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="is-size-5">
            Vous souhaiter auditer votre application ? Nous pouvons réaliser un
            audit et vous fournir des indicateurs concernant sa qualité
            (maintenabilité, sécurité, consistance, ...) ?
          </p>
          <p className="is-size-5">
            Vous envisagez peut être la transformation d'une application pour la
            porter sur le cloud ? Nous pouvons vous proposer un plan de
            transition avec des architectures applicatives adaptées à vos
            besoins, former et accompagner vos équipes et vous aider à
            industrialiser l'exploitation de vos produits.
          </p>
        </div>
      </div>
    </section>
  );
};

export default NosServices;
