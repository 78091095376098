import React, { useEffect } from "react";
import bannerBackground from "../assets/images/mentions-legales/helloquence-OQMZwNd3ThU-unsplash.jpg";

const MentionsLegales = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section
        className="hero is-medium"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(${bannerBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-3 has-text-white">Mentions légales</h1>
          </div>
        </div>
      </section>
      <section className="container mentions-legales">
        <p>
          Conformément aux dispositions de l'article 6 de la loi n° 2004-575 du
          21 juin 2004 pour la confiance en l'économie numérique, nous vous
          informons que :
        </p>
        <p className="subtitle is-4">Edition du site</p>
        <p>
          Le site it-matters.fr est édité par la société{" "}
          <strong>IT Matters</strong> EURL, au capital social de 3000 € euros,
          immatriculée au Registre du commerce et des sociétés de{" "}
          <strong>Nîmes</strong> sous le numéro <strong>881 594 683</strong> et
          dont le siège social est situé 236, rue Jacques Prévert, 30310,
          Vergèze.
        </p>
        <p className="subtitle is-4">Responsable de publication</p>
        <p>Monsieur Alexandre Trigueros, Gérant</p>
        <p className="subtitle is-4">Hébergeur</p>
        <p>
          Le site it-matters.fr est hébergé par Firebase, 188 King ST, San
          Francisco, CA 94107, United States, https://firebase.google.com/.
        </p>
        <p className="subtitle is-4">Nous contacter</p>
        <p>
          Pour toutes suggestions, informations, réactions concernant ce site,
          n'hésitez pas à nous contacter
        </p>
        <ul>
          <li>
            Par email:{" "}
            <a href="mailto:contact@it-matters.fr">contact@it-matters.fr</a>
          </li>
          <li>
            Par courrier: IT Matters, 236 rue Jacques Prévert, 30310 Vergèze
          </li>
        </ul>
        <p className="subtitle is-4">Données personnelles</p>
        <p  style={{paddingBottom: "5rem"}}>
          Le site it-matters.fr ne collecte ni ne traite de données
          personnelles.
        </p>
      </section>
    </>
  );
};

export default MentionsLegales;
