import React, { useState } from "react";
import { Link } from "react-router-dom";

import { RouteNames } from "../RouteNames";
import classnames from "classnames";
import logo from "../assets/images/logo.png";

const Navbar = () => {
  const [isMenuShowing, setIsMenuShowing] = useState(false);

  const burgerClasses = classnames("navbar-burger", {
    "is-active": isMenuShowing,
  });

  const menuClasses = classnames("navbar-menu", {
    "is-active": isMenuShowing,
  });
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to={RouteNames.Accueil} className="navbar-item">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <a
          href="/"
          className={burgerClasses}
          aria-label="menu"
          aria-expanded="false"
          onClick={(e) => {
            e.preventDefault();
            setIsMenuShowing(!isMenuShowing);
          }}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div className={menuClasses}>
        <div className="navbar-start">
          <a className="navbar-item has-text-white" href={RouteNames.Accueil}>
            Qui sommes nous
          </a>
          <a className="navbar-item has-text-white" href={RouteNames.NosServices}>
            Nos services
          </a>
          <a className="navbar-item has-text-white" target="_blank" href="https://www.trigueros.tech">
            Blog
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
