import React from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../RouteNames';

const Footer = () => {
	return (
		<footer className="footer is-small">
			<div className="container has-text-centered">
				<p>
					IT Matters – 236 Rue Jacques Prévent 30310 Vergèze –{' '}
					<Link to={RouteNames.MentionsLegales} className="has-text-white">Mentions légales</Link>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
